import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Link to="/">
          <h1>{siteTitle}</h1>
        </Link>
        <div id="main" style={{ display: 'flex' }}>
          <div className="news">
            <div className="news-body">
              <h2 className="news-title">{post.frontmatter.title}</h2>

              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </div>
            <small className="postedby">
              Posted by {post.frontmatter.author} on {post.frontmatter.date}
            </small>
          </div>
          <ul className="navigation">
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </div>
        <div id="footerlink" className="center1">
          <Link to="/archive">back to archive</Link>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
      }
    }
  }
`
